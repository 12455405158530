import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import {
  Container,
  Box,
  useTheme,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  Pagination,
  Stack,
  TextField,
  IconButton,
  darken,
  Divider,
  InputAdornment,
  FormControl,
  Tabs,
  Tab,
  Drawer,
  Modal
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { openModal, closeModal } from "../features/slices/modalSlice";
import CopiedTooltip from "../components/common/CopiedTooltip";
import CircularProgress from "@mui/material/CircularProgress";

import { Close, ArrowBack, CopyAll } from "@mui/icons-material";

import { selectCurrentToken } from "../features/slices/authSlice";

import {
  useGetGameProviderQuery,
  useLazyGetGameItemQuery,
  useLazyGetLaunchGameQuery,
  useGetGameCategoryQuery,
} from "../features/api/gameApiSlice";

import GameCard from "../components/Game/GameCard";
import Backdrop from "../components/common/Backdrop";
import GameProviderDrawer from "../components/Game/GameProviderDrawer";
import TextCarousel from "../components/common/TextCarousel";
import Image from "../components/common/Image";
import SearchBar from "../components/common/SearchBar";
import { lighten } from "@material-ui/core";
import * as Constant from "../features/constant";

const pattern = /^((http|https|ftp):\/\/)/;

const Game = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector(selectCurrentToken);
  const { gameCategoryCode, gameProviderCode } = useParams();
  const [offset, setOffset] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [group, setGroup] = useState("ALL");
  const [currentProviderId, setCurrentProviderId] = useState("");
  const [appProviderName, setAppProviderName] = useState("");
  const [gameProviderDrawer, setGameProviderDrawer] = useState(false);
  const [appGameDisplay, setAppGameDisplay] = useState(false);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletView = useMediaQuery(theme.breakpoints.down("md"));
  const lang = localStorage.getItem("language");
  const [gameGroupDrawer, setGameGroupDrawer] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [interactionBlocked, setInteractionBlocked] = useState(false);
  const handleGameGroupDrawerToggle = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setGameGroupDrawer(false);
  };

  useEffect(() => {
    // Track the PageView event with Facebook Pixel
    window.fbq("trackCustom", "PageView");
  }, []);

  const liveCasinoSeo = [
    <>
      <Typography fontWeight={600} color="white">
        <h1>{t("game.casinoseo.firsttitle")}</h1>
      </Typography>
      <Typography mt={2} color="white">
        {t("game.casinoseo.firstdesc")}
      </Typography>
      <Typography fontWeight={600} color="white">
        <h2>{t("game.casinoseo.secondtitle")}</h2>
      </Typography>
      <Typography mt={2} color="white">
        {t("game.casinoseo.seconddesc_1")}<br /><br />
        {t("game.casinoseo.seconddesc_2")}
      </Typography>
      <Typography fontWeight={600} color="white">
        <h2>{t("game.casinoseo.thirdtitle")}</h2>
      </Typography>
      <Typography mt={2} color="white">
        {t("game.casinoseo.thirddesc")}
      </Typography>
    </>,
  ];

  const slotSeo = [
    <>
      {/* <Typography fontWeight={600} color="white">
        <h1>{t("game.slotseo.firsttitle")}</h1>
      </Typography>
      <Typography mt={2} color="white">
        {t("game.slotseo.firstdesc_1")}<br/><br/>
        {t("game.slotseo.firstdesc_2")}
      </Typography>
      <Typography fontWeight={600} color="white">
        <h2>{t("game.slotseo.secondtitle")}</h2>
      </Typography>
      <Typography mt={2} color="white">
        {t("game.slotseo.seconddesc")}
      </Typography>
      <Typography fontWeight={600} color="white">
        <h3>{t("game.slotseo.thirdtitle")}</h3>
      </Typography>
      <Typography mt={2} color="white">
        {t("game.slotseo.thirddesc_1")}<br/><br/>
        {t("game.slotseo.thirddesc_2")}
      </Typography>
      <Typography fontWeight={600} color="white">
        <h4>{t("game.slotseo.fourthtitle")}</h4>
      </Typography>
      <Typography mt={2} color="white">
        {t("game.slotseo.fourthdesc_1")}<br/><br/>
        {t("game.slotseo.fourthdesc_2")}
      </Typography> */}
      <Typography mt={2} color="white" dangerouslySetInnerHTML={{ __html: t("game.slotseo.desc") }}></Typography>
    </>,
  ];

  const sportbookSeo = [
    <>
      <Typography fontWeight={600} color="white">
        <h1>{t("game.sportbookseo.firsttitle")}</h1>
      </Typography>
      <Typography mt={2} color="white">
        {t("game.sportbookseo.firstdesc")}
      </Typography>
      <Typography fontWeight={600} color="white">
        <h2>{t("game.sportbookseo.secondtitle")}</h2>
      </Typography>
      <Typography mt={2} color="white">
        {t("game.sportbookseo.seconddesc_1")}<br /><br />
        {t("game.sportbookseo.seconddesc_2")}
      </Typography>
      <Typography fontWeight={600} color="white">
        <h2>{t("game.sportbookseo.thirdtitle")}</h2>
      </Typography>
      <Typography mt={2} color="white">
        {t("game.sportbookseo.thirddesc_1")}<br /><br />
        {t("game.sportbookseo.thirddesc_2")}
      </Typography>
    </>,
  ];

  const fishHunterSeo = [
    <>
      <Typography fontWeight={600} color="white">
        <h1>{t("game.fishingseo.firsttitle")}</h1>
      </Typography>
      <Typography mt={2} color="white">
        {t("game.fishingseo.firstdesc")}
      </Typography>
      <Typography fontWeight={600} color="white">
        <h2>{t("game.fishingseo.secondtitle")}</h2>
      </Typography>
      <Typography mt={2} color="white">
        {t("game.fishingseo.seconddesc_1")}<br /><br />
        {t("game.fishingseo.seconddesc_2")}
      </Typography>
      <Typography fontWeight={600} color="white">
        <h2>{t("game.fishingseo.thirdtitle")}</h2>
      </Typography>
      <Typography mt={2} color="white">
        {t("game.fishingseo.thirddesc")}
      </Typography>
      <Typography fontWeight={600} color="white">
        <h2>{t("game.fishingseo.fourthtitle")}</h2>
      </Typography>
      <Typography mt={2} color="white">
        <ul>
          <li>{t("game.fishingseo.fourthdesc_1")}</li>
          <li>{t("game.fishingseo.fourthdesc_2")}</li>
          <li>{t("game.fishingseo.fourthdesc_3")}</li>
          <li>{t("game.fishingseo.fourthdesc_4")}</li>
        </ul>
        {t("game.fishingseo.fourthdesc_5")}
      </Typography>
    </>,
  ];

  const customMenuItems = Constant.getCustomMenuItems();

  const [searchInput, setSearchInput] = useState('');
  const handleSearchInputChange = event => {
    setSearchInput(event.target.value);
  };

  let gameCategory = gameCategoryCode;
  if (gameCategory) {
    gameCategory = gameCategory.replaceAll("-", "_");
  }
  const {
    data: allGameCategory,
    isFetching: isAllGameCategoryFetching,
    isLoading: isAllGameCategoryLoading,
  } = useGetGameCategoryQuery({ prefetch: true });

  const {
    data: gameProvider,
    isFetching: isGameProviderFetching,
    isLoading: isGameProviderLoading,
  } = useGetGameProviderQuery({ category: gameCategory }, { prefetch: true });

  const [
    getGameItem,
    {
      data: gameItem,
      isFetching: isGameItemFetching,
      isLoading: isGameItemLoading,
      isError: isGameItemError,
      isSuccess: isGameItemSuccess,
      error: gameItemError,
    },
  ] = useLazyGetGameItemQuery({ prefetch: true });

  const [
    getLaunchGame,
    {
      data: launchGame,
      isFetching: isLaunchGameFetching,
      isLoading: isLaunchGameLoading,
      isError: isLaunchGameError,
      isSuccess: isLaunchGameSuccess,
      error: launchGameError,
    },
  ] = useLazyGetLaunchGameQuery({ prefetch: true });

  const categoryTabChangeHandler = (e, value) => {
    if (value !== undefined) {
      let selected = customMenuItems[value];
      if (selected) {
        window.fbq('trackCustom', 'category_' + selected?.code);

        navigate(Constant.getPath("/game/" + selected.code?.toLowerCase()));
      }
    }
  };

  useEffect(() => {
    setAppGameDisplay(false);
    getGameItem({
      category: gameCategory,
      offset: offset - 1,
      providerId: currentProviderId || "",
      keyword: searchQuery || "",
      group: group || "ALL",
    });
  }, [offset, currentProviderId, gameCategory, searchQuery, group]);

  useEffect(() => {
    setOffset(1);
  }, [currentProviderId]);

  useEffect(() => {
    if (location?.state?.providerId != null)
      setCurrentProviderId(location?.state?.providerId);
  }, [location?.state?.providerId]);

  useEffect(() => {
    if (gameProviderCode !== undefined) {
      let selectedGameProvider = gameProvider?.result?.find((provider) => provider.name === gameProviderCode.replaceAll("-", " ").toUpperCase());
      if (selectedGameProvider) {
        setCurrentProviderId(selectedGameProvider.id);
      }
    }
    else {
      setCurrentProviderId(
        location?.state?.providerId || gameProvider?.result[0]?.id
      );
    }

    if (
      allGameCategory?.result?.find(
        (cat) => cat.code?.toLowerCase() === gameCategory
      )?.hasGameList &&
      gameProvider?.result[0]?.grouping
    ) {
      setGroup(gameProvider?.result[0]?.grouping[0]?.code || "");
    }
  }, [gameProvider]);

  useEffect(() => {

    if (isLaunchGameLoading || isLaunchGameFetching) {
      setShowLoading(true);
      setInteractionBlocked(true);
    }



    if (!isLaunchGameLoading && !isLaunchGameFetching && isLaunchGameSuccess) {
      if (launchGame?.result?.gameType === "APP") {
        setAppGameDisplay(true);
      } else if (launchGame?.result?.gameType === "WEB") {
        if (launchGame?.result?.iframeSupported == true) {
          window.location.href = process.env.REACT_APP_GAME_URL_IFRAME + launchGame.result.gameUrl;
        }
        else {
          window.open(launchGame.result.gameUrl, "_blank");
        }
      }
    }


    setTimeout(() => {
      setShowLoading(false);
      setInteractionBlocked(false); // Re-enable user interaction
    }, 10000);


  }, [isLaunchGameLoading, isLaunchGameFetching, isLaunchGameSuccess, launchGame]);

  useEffect(() => {
    if (isGameItemError) {
      dispatch(
        openModal({
          title: gameItemError?.data?.message,
        })
      );
    }
  }, [isGameItemError]);

  useEffect(() => {
    if (isLaunchGameError) {
      dispatch(
        openModal({
          title: launchGameError?.data?.message,
        })
      );
    }
  }, [isLaunchGameError]);

  const handlePageChange = (e, p) => {
    setOffset(p);
    window.scroll(0, 0);
  };

  return (

    <>
      {/* Loading Overlay */}
      <Modal open={showLoading}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1300,
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              color: "#fff",
            }}
          >
            {/* current image not compatible, doesnt show correctly*/}
            {/* <img
            src="/logo192.png"
            alt="Website Logo"
            style={{ width: "100px", marginBottom: "20px" }}
          /> */}
            <CircularProgress color="inherit" />
          </Box>
        </Box>
      </Modal>

      {/* Global Block Interaction */}
      {interactionBlocked && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1200,
            pointerEvents: "none", // Disable pointer events
          }}
        />
      )}

      <Box
        sx={{
          background: `url('${gameProvider?.webBackground[0]?.thumbnailUrl}')`,
        }}
      >
        {/* Banner Section */}
        <Box>
          {!mobileView && (
            <Image
              src={
                allGameCategory?.result?.find(
                  (cat) => cat.code?.toLowerCase() === gameCategory
                )?.bannerUrl
              }
              alt={Constant.ALT_TAG_CATEGORY_BANNER + gameCategory}
            />
          )}
        </Box>
        {/* All, Hot & Other Categories with search bar */}
        {!mobileView && allGameCategory?.result?.find(
          (cat) => cat.code?.toLowerCase() === gameCategory
        )?.hasGameList && (
            <Box
              sx={{
                width: "100%",
                backgroundColor: theme.palette.background.paper,
                pt: !mobileView ? 3 : 1,
                pb: !mobileView ? 1.2 : 0,
                px: !mobileView ? 1.2 : 0
              }}
            >
              <Container style={{ maxWidth: '3000px' }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: !mobileView ? "space-between" : "center",
                  }}
                >
                  <Box display="flex" sx={{
                    border: gameProvider?.result?.find((provider) => provider.id === currentProviderId)?.grouping ? "1px solid white" : "none",
                    py: "5px",
                    px: "10px",
                    borderRadius: "30px",
                    float: "left"
                  }}>
                    {gameProvider?.result
                      ?.find((provider) => provider.id === currentProviderId)
                      ?.grouping?.map((grouping, index) => (
                        <Button
                          onClick={() => setGroup(grouping?.code)}
                          sx={{
                            "&:hover": {
                              background: "transparent",
                            },
                            mx: "10px"
                          }}
                          disableRipple
                        >
                          {/* {group === grouping?.code && (
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: "50%",
                            transform: "translateX(-50%)",
                            borderBottom: "2px solid",
                            width: "50%",
                          }}
                        />
                      )} */}
                          <Typography sx={{
                            color: (group === grouping?.code) ? theme.palette.secondary.main : "white",
                            fontSize: "14px"
                          }}>
                            {t("gamegroup." + grouping?.code?.toLowerCase())}
                          </Typography>
                        </Button>
                      ))}
                  </Box>
                  <Box sx={{
                    float: "right"
                  }}>
                    <TextField
                      sx={{
                        width: "180px",
                        ".MuiOutlinedInput-input": {
                          padding: "9px 14px"
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderRadius: "30px 0 0 30px",
                        }
                      }}
                      placeholder={t("gamegroup.searchgame")}
                      value={searchInput}
                      onChange={(e) => {
                        setSearchInput(e.target.value);
                        setSearchQuery(e.target.value); // Update query as you type
                      }}
                    />
                    <Button sx={{
                      backgroundImage: 'url(/assets/img/red_small.png)',
                      backgroundSize: "100% 100%",
                      py: "10px",
                      px: "15px",
                      borderRadius: "0 30px 30px 0"
                    }}
                      onClick={() => setSearchQuery(searchInput)}
                    >
                      <Typography sx={{
                        fontSize: "14px"
                      }}>
                        {t("gamegroup.searchall")}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Container>
            </Box>
          )}
        {/* Mobile view All, Hot, New game selection */}
        {mobileView && (
          <Drawer
            anchor="bottom"
            variant="temporary"
            open={gameGroupDrawer}
            onClose={handleGameGroupDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                bgcolor: theme.palette.background.paper,
              },
            }}
          >
            <Container sx={{
              textAlign: "center",
              py: "5px"
            }}>
              {gameProvider?.result
                ?.find((provider) => provider.id === currentProviderId)
                ?.grouping?.map((grouping, index) => (
                  <Button
                    onClick={() => {
                      setGroup(grouping?.code);
                      setGameGroupDrawer(false);
                    }}
                    sx={{
                      "&:hover": {
                        background: "transparent",
                      },
                      mx: "10px",
                      border: (group === grouping?.code) ? "1px solid " + theme.palette.secondary.main : "1px solid white",
                      width: "30%",
                      my: "5px"
                    }}
                    disableRipple
                  >
                    <Typography sx={{
                      color: (group === grouping?.code) ? theme.palette.secondary.main : "white",
                      fontSize: "14px"
                    }}>
                      {t("gamegroup." + grouping?.code?.toLowerCase())}
                    </Typography>
                  </Button>
                ))}
            </Container>
          </Drawer>
        )}
        {/* Game Provider Filter */}
        <Box
          pt={{ xs: 1, sm: 1, lg: 1, xl: 1 }}
          sx={{
            backgroundColor:
              location.pathname !== "/game/sportbook" &&
              location.pathname !== "/game/live-casino" &&
              theme.palette.background.paper,
          }}
        >
          {mobileView && (
            <Box pb={2} pt="53px">
              <Tabs variant="scrollable"
                scrollButtons="auto" sx={{
                  ".MuiTabs-scroller": {
                    height: "30px"
                  },
                  minHeight: "30px",
                  marginBottom: "5px",
                  mx: "5px"
                }}
                value={customMenuItems.findIndex(x => x.code === gameCategory.toUpperCase())}
                onChange={categoryTabChangeHandler}>
                {customMenuItems.map((cmi) => {
                  return <Tab icon={
                    <Image
                      width="16px"
                      height="16px"
                      src={cmi.imageLogoOnly}
                    />}
                    iconPosition="start"
                    sx={{
                      color: cmi.code.toLowerCase() == gameCategory ? "black !important" : "white",
                      backgroundColor: cmi.code.toLowerCase() == gameCategory ? theme.palette.primary.main : "transparent",
                      borderRadius: "5px",
                      display: "flex",
                      fontSize: "14px",
                      py: "0",
                      minHeight: "30px",
                      px: "5px",
                      minWidth: "60px",
                      mx: "5px"
                    }}
                    disableRipple
                    key={cmi.code} label={t('gamecategory.' + cmi.code.toLowerCase().replaceAll("_", "").replaceAll("-", ""))} ></Tab>
                })}
              </Tabs>
              <Grid container justifyContent="space-between">
                <TextField
                  sx={{
                    float: "left",
                    width: gameProvider?.result?.find((provider) => provider.id === currentProviderId)?.grouping ? "70%" : "100%",
                    marginLeft: "16px",
                    marginRight: gameProvider?.result?.find((provider) => provider.id === currentProviderId)?.grouping ? "0" : "16px",
                    ".MuiOutlinedInput-input": {
                      padding: "4px 14px",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderRadius: "6px",
                    }
                  }}
                  placeholder={t("gamegroup.searchgame")}
                  value={searchQuery}
                  // onChange= {handleSearchInputChange}
                  onInput={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                />
                {gameProvider?.result?.find((provider) => provider.id === currentProviderId)?.grouping && (
                  <Button sx={{
                    background: "#F68E26",
                    width: "20%",
                    float: "right",
                    marginRight: "16px",
                    py: "2px"
                  }}
                    onClick={() => setGameGroupDrawer(true)}>
                    <Typography sx={{ fontSize: "14px" }}>{t("gamegroup.filter")}</Typography>
                  </Button>
                )}
              </Grid>
            </Box>
          )}
          <Container style={{ maxWidth: '3000px' }}>
            {mobileView ? (
              <>
                {/* <Button
                onClick={() => setGameProviderDrawer(true)}
                variant="outlined"
                fullWidth
                sx={{
                  "&.MuiButtonBase-root:hover": {
                    backgroundColor: "transparent",
                  },
                  borderColor: theme.palette.primary.main,
                  borderRadius: 4,
                  maxWidth: "100%",
                }}
                disableRipple
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    textTransform: "UPPERCASE",
                    fontWeight: "600",
                  }}
                >
                  {t("game.gameproviders")}
                </Typography>
                <KeyboardDoubleArrowRight />
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    textTransform: "UPPERCASE",
                    fontWeight: "600",
                  }}
                >
                  {gameProvider?.result?.find(
                    (provider) => provider.id === currentProviderId
                  )?.name || "All"}
                </Typography>
              </Button> */}
                {allGameCategory?.result?.find(
                  (cat) => cat.code?.toLowerCase() === gameCategory
                )?.hasGameList && (
                    <>
                      <Grid container spacing={2} sx={{
                        '&::-webkit-scrollbar': { display: "none" },
                        overflowX: "scroll",
                        width: "100%",
                        display: "-webkit-inline-box",
                        flexWrap: "nowrap",
                        pb: "3px",
                        marginLeft: "0"
                      }}>
                        {gameProvider?.result?.map((provider, index) => (
                          <Grid key={index} item sx={{ width: "60px", textAlign: "center", paddingLeft: "0 !important" }}>
                            <Box
                              sx={{
                                border: 1,
                                display: "flex",
                                justifyContent: "center",
                                height: "50px",
                                width: "50px",
                                alignItems: "center",
                                boxSizing: "border-box",
                                wordWrap: "break-word",
                                cursor: "pointer",
                                borderRadius: "10px",
                                borderColor:
                                  currentProviderId === provider.id
                                    ? theme.palette.primary.main
                                    : darken(theme.palette.background.paper, 0.2),
                                overflow: "hidden",
                                margin: "0 auto"
                              }}
                              onClick={() => {
                                setGroup("ALL");
                                navigate(Constant.getPath("/game/" + gameCategory + "/" + provider.name.replaceAll(" ", "-").toLowerCase()));
                                setSearchQuery("");
                                setCurrentProviderId(provider.id);
                                window.fbq('trackCustom', 'click_' + provider?.name);
                              }}
                            >
                              <img
                                src={
                                  currentProviderId === provider.id
                                    ? provider.logoSelectedUrl
                                    : provider.logoUrl
                                }
                                alt={Constant.ALT_TAG_GENERAL_PROVIDER + provider.name}
                              />
                            </Box>
                            <Typography
                              color={currentProviderId === provider.id ? theme.palette.tertiary.main : "white"}
                              fontSize="12px"
                              pt="2px"
                            >
                              {provider.name.split(" ")[0]}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  )}
              </>
            ) : (
              <>
                {allGameCategory?.result?.find(
                  (cat) => cat.code?.toLowerCase() === gameCategory
                )?.hasGameList && (
                    <>
                      <Grid container spacing={2}>
                        {gameProvider?.result?.map((provider, index) => (
                          <Grid key={index} item sx={{ width: "65px", textAlign: "center" }}>
                            <Box
                              sx={{
                                border: 1,
                                display: "flex",
                                justifyContent: "center",
                                height: "50px",
                                width: "50px",
                                alignItems: "center",
                                boxSizing: "border-box",
                                wordWrap: "break-word",
                                cursor: "pointer",
                                borderRadius: "50%",
                                borderColor:
                                  currentProviderId === provider.id
                                    ? theme.palette.primary.main
                                    : darken(theme.palette.background.paper, 0.2),
                                overflow: "hidden",
                                margin: "0 auto"
                              }}
                              onClick={() => {
                                setSearchInput("");
                                setSearchQuery("");
                                setCurrentProviderId(provider.id);
                                setGroup("ALL");
                                window.fbq('trackCustom', 'click_' + provider?.name);
                                navigate(Constant.getPath("/game/" + gameCategory + "/" + provider.name.replaceAll(" ", "-").toLowerCase()));
                              }}
                            >
                              <img
                                src={
                                  currentProviderId === provider.id
                                    ? provider.logoSelectedUrl
                                    : provider.logoUrl
                                }
                                alt={Constant.ALT_TAG_GENERAL_PROVIDER + provider.name}
                              />
                            </Box>
                            <Typography
                              color={currentProviderId === provider.id ? theme.palette.tertiary.main : "white"}
                              fontSize="12px"
                              pt="8px"
                            >
                              {provider.name.split(" ")[0]}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  )}
              </>
            )}
          </Container>
        </Box>
        <Container style={{ maxWidth: '3000px' }}>
          {/* Mobile View Filter Drawer */}
          <GameProviderDrawer
            gameProviderDrawer={gameProviderDrawer}
            setGameProviderDrawer={setGameProviderDrawer}
            gameProvider={gameProvider?.result}
            currentProviderId={currentProviderId}
            setOffset={setOffset}
            setCurrentProviderId={setCurrentProviderId}
            gameCategory={gameCategory}
          />
          {/* App Game PopUp */}
          {(isLaunchGameLoading || isLaunchGameFetching)}
          {!isLaunchGameLoading &&
            !isLaunchGameFetching &&
            isLaunchGameSuccess && (
              <>
                {appGameDisplay && (
                  <>
                    <Box display="flex" flexDirection="column" pt={2}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                        alignSelf="center"
                        pt={2}
                      >
                        <Image src={launchGame?.result?.bannerUrl} />
                        <Typography color={theme.palette.primary.main} pt={2}>
                          {`EASY STEP BY STEP TO PLAY ${appProviderName} ON YOUR MOBILE`}
                        </Typography>
                        <Typography color={theme.palette.primary.main} pt={2}>
                          Step 1
                        </Typography>
                        <Typography>
                          {`Transfer fund into ${appProviderName} wallet to get your login ID and passwordon the below section of this page.`}
                        </Typography>
                        <Typography color={theme.palette.primary.main} pt={2}>
                          Step 2
                        </Typography>
                        <Typography>
                          {`Download ${appProviderName} from the download link below.`}
                        </Typography>
                        <Typography color={theme.palette.primary.main} pt={2}>
                          Step 3
                        </Typography>
                        <Typography pb={3}>
                          {`Launch the apps in your mobile and login with the ID and password from below.`}
                        </Typography>
                        <Divider />
                        <FormControl
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            pt: 4,
                            "& .MuiTextField-root": {
                              pb: 3,
                            },
                          }}
                        >
                          <TextField
                            label="Username"
                            value={launchGame?.result?.mobileAppUsername}
                            InputLabelProps={{ shrink: true }}
                            disabled
                            type="text"
                            size="small"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    disableRipple
                                    sx={{
                                      "&.MuiButtonBase-root:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                  >
                                    <CopiedTooltip
                                      text={launchGame?.result?.mobileAppUsername}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            label="Password"
                            value={launchGame?.result?.mobileAppPassword}
                            InputLabelProps={{ shrink: true }}
                            disabled
                            type="text"
                            size="small"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    disableRipple
                                    sx={{
                                      "&.MuiButtonBase-root:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                  >
                                    <CopiedTooltip
                                      text={launchGame?.result?.mobileAppPassword}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Box>
                      <Box display="flex" alignSelf="center">
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: theme.palette.button.primary.main,
                          }}
                          onClick={() => {
                            let url = launchGame.result.mobileAppUrlIos[0];
                            if (
                              !pattern.test(launchGame.result.mobileAppUrlIos[0])
                            ) {
                              url = "https://" + url;
                            }
                            window.open(url, "_blank");
                          }}
                        >
                          IOS
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: theme.palette.button.primary.main,
                            ml: 2,
                          }}
                          onClick={() => {
                            let url = launchGame.result.mobileAppUrlAndroid[0];
                            if (
                              !pattern.test(
                                launchGame.result.mobileAppUrlAndroid[0]
                              )
                            ) {
                              url = "https://" + url;
                            }
                            window.open(url, "_blank");
                          }}
                        >
                          ANDROID
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </>
            )}
          {/* Game Item List */}
          <Box pb={5} mb={4}>
            {!isGameItemLoading &&
              isGameItemSuccess &&
              allGameCategory?.result?.find(
                (cat) => cat.code?.toLowerCase() === gameCategory
              )?.hasGameList && (
                <>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                    mt={mobileView ? 0 : 1}
                    width={{ xs: "100%", sm: "90%" }}
                    margin={{ xs: undefined, sm: "0 auto" }}
                  >
                    {gameItem?.result?.data?.map((game, index) => (
                      <>
                        {!appGameDisplay && (
                          <Grid
                            item
                            xs={3}
                            sm={2}
                            md={1.5}
                            key={index}
                            display="flex"
                            justifyContent="center"
                          >
                            <GameCard
                              imageUrl={game?.thumbnailUrl}
                              imageWidth={
                                mobileView
                                  ? "80px"
                                  : tabletView
                                    ? "120px"
                                    : "150px"
                              }
                              imageHeight={
                                mobileView
                                  ? "80px"
                                  : tabletView
                                    ? "120px"
                                    : "150px"
                              }
                              alt={Constant.ALT_TAG_GENERAL_GAME + game.name}
                              onClick={() => {
                                if (!token) {
                                  if (mobileView) {
                                    navigate(Constant.getPath("/login"));
                                  }
                                  else {
                                    dispatch(
                                      openModal({
                                        title: t("dialog.proceedtologin"),
                                        button: [
                                          {
                                            label: t("dialog.login"),
                                            onSubmit: () => {
                                              dispatch(closeModal());
                                              if (mobileView) {
                                                navigate(Constant.getPath("/login"));
                                              }
                                            },
                                          },
                                        ],
                                      })
                                    );
                                  }
                                } else {
                                  // if (game?.disabled) {
                                  //   dispatch(
                                  //     openModal({
                                  //       title: t("game.gameunavailable"),
                                  //     })
                                  //   );
                                  // } else {
                                  if (game?.name.toLowerCase().includes("evolution")) {
                                    window.fbq('trackCustom', 'ClickEvolution');
                                  }
                                  setAppProviderName(
                                    gameProvider?.result?.find(
                                      (provider) =>
                                        provider?.id === game?.providerId
                                    )?.name
                                  );
                                  getLaunchGame({
                                    id: game?.id,
                                    type: game?.gameType,
                                    isMobile: mobileView
                                  });

                                  // }
                                }
                              }}
                              title={game?.name}
                              subtitle={
                                gameProvider?.result?.find(
                                  (provider) => provider?.id === game?.providerId
                                )?.name
                              }
                              description={{}}
                            />
                          </Grid>
                        )}
                      </>
                    ))}
                  </Grid>
                  {gameItem?.result?.totalPage !== 0 && !appGameDisplay && (
                    <Stack
                      mt={tabletView ? 1 : 2}
                      spacing={2}
                      size="small"
                      alignItems="center"
                    >
                      <Pagination
                        onChange={handlePageChange}
                        count={gameItem?.result?.totalPage}
                        shape="rounded"
                        page={offset}
                        sx={{
                          "& .MuiPaginationItem-root": {
                            color: "white",
                            "&.Mui-selected": {
                              background: lighten(
                                theme.palette.background.paper,
                                0.02
                              ),
                              color: "white",
                            },
                          },
                        }}
                      />
                    </Stack>
                  )}
                </>
              )}
            {!allGameCategory?.result?.find(
              (cat) => cat.code?.toLowerCase() === gameCategory
            )?.hasGameList && (
                <Grid container spacing={3} sx={{
                  justifyContent: "center"
                }}>
                  {gameProvider?.result?.map((provider, index) => (
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      md={2}
                      key={index}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (!token) {
                          if (provider?.name.toLowerCase().includes("evolution")) {
                            window.fbq('trackCustom', 'ClickEvolution');
                          }
                          dispatch(
                            openModal({
                              title: t("dialog.proceedtologin"),
                              button: [
                                {
                                  label: t("dialog.login"),
                                  onSubmit: () => dispatch(closeModal()),
                                },
                              ],
                            })
                          );
                        } else {
                          if (provider?.name.toLowerCase().includes("evolution")) {
                            window.fbq('trackCustom', 'ClickEvolution');
                          }
                          setAppProviderName(provider?.name);
                          window.fbq('trackCustom', 'click_' + provider?.name);

                          getLaunchGame({
                            id: provider?.id,
                            type: provider?.gameType,
                            category: gameCategory,
                            isMobile: mobileView
                          });
                        }
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Box overflow="hidden">
                          <Image hovered={true} src={provider.providerImgUrl} sx={{ borderRadius: 4 }} alt={Constant.ALT_TAG_GENERAL_PROVIDER + provider.name} />
                        </Box>
                        <Typography
                          color={theme.palette.primary.main}
                          fontSize={13}
                          fontWeight={800}
                          mt={2}
                        >
                          {provider.name}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
          </Box>
          {!mobileView && (lang == "en" || lang == "bd" || !lang) && (
            <Box py={5}>
              <TextCarousel
                html={
                  // gameCategory === "live_casino"
                  //   ? liveCasinoSeo
                  gameCategory === "slot"
                    ? slotSeo
                    // : gameCategory === "sportbook"
                    // ? sportbookSeo
                    // : gameCategory === "fish_hunter"
                    // ? fishHunterSeo
                    : null
                }
                slidesPerView={1}
                pagination={true}
              />
            </Box>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Game;
