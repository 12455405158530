import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Grid,
  Stack,
  Box,
  useMediaQuery,
  useTheme,
  Divider,
  ListItemButton,
  Typography,
  Button,
  Container,
  IconButton,
} from "@mui/material";
import {
  AccountCircle as AccountCircleIcon,
  LocalAtm as LocalAtmIcon,
  PointOfSale as PointOfSaleIcon,
  Restore as RestoreIcon,
  ListAlt as ListAltIcon,
  Refresh,
  Discount,
  LockOpen, Forward,
} from "@mui/icons-material";
import UserProfile from "../components/wallet/UserProfile";
import Deposit from "../components/wallet/Deposit";
import Withdrawal from "../components/wallet/Withdrawal";
import Transaction from "../components/wallet/Transaction";
import Backdrop from "../components/common/Backdrop";
import BetRecord from "../components/wallet/BetRecord";
import BankAccount from "../components/wallet/BankAccount";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useGetShareLinkQuery,
  usePlayerBanksMutation,
  useGetBankAccountsQuery
} from "../features/api/userApiSlice";
import {
  useGetDepositDetailsQuery,
  useDepositMutation,
  useGetWithdrawDetailsQuery,
  useWithdrawMutation,
  useGetHistoryMutation,
} from "../features/api/transactionApiSlice";
import { apiSlice } from "../features/api/apiSlice";
import i18n, { t } from "i18next";
import { useGetPromotionRecordMutation } from "../features/api/bonusApiSlice";
import { useListBetHistoryMutation } from "../features/api/gameApiSlice";
import PromotionRecord from "../components/wallet/PromotionRecord";
import ChangePassword from "../components/wallet/ChangePassword";
import WalletCard from "../components/common/WalletCard";
import Image from "../components/common/Image";
import * as Constant from "../features/constant";
import PromoCenter from "./PromoCenter";

const PATH = process.env.PUBLIC_URL;

const ProfileWallet = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletView = useMediaQuery(theme.breakpoints.down("lg"));
  const [selectedScreen, setSelectedScreen] = useState(
    location?.state?.screen || "My Account"
  );

  const searchParams = new URLSearchParams(location.search);
  const paramTab = searchParams.get("tab");

  useEffect(() => {
    // Track the PageView event with Facebook Pixel
    window.fbq("trackCustom", "PageView");
  }, []);

  useEffect(() => {
    if (paramTab) {
      navigate(Constant.getPath("/profileWallet"), {
        state: {
          screen: paramTab,
        },
      });
    }
  }, [paramTab]);

  const screens = [
    {
      label: i18n.t("walletcard.deposit"),
      code: "Deposit",
      icon: (
        <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF", }}>
          <Image
            width="30px"
            height="30px"
            src={PATH + `/assets/img/wallet/wallet_card/Deposit.png`}
          />
        </div>
      ),
      category: t("walletcard.banking"),
    },
    {
      label: i18n.t("walletcard.promocenter"),
      code: "Promo Center",
      icon: (
        <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF", }}>
          <Image
            width="30px"
            height="30px"
            src={PATH + `/assets/img/wallet/wallet_card/promocenter.svg`}
          />
        </div>
      ),
      category: t("walletcard.banking"),
    },
    {
      label: i18n.t("walletcard.withdrawal"),
      code: "Withdrawal",
      icon: (
        <div style={{
          backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF", // Border style and color
        }}>
          <Image
            width="30px"
            height="30px"
            src={PATH + `/assets/img/wallet/wallet_card/Withdraw.png`}
          />
        </div>
      ),
      category: t("walletcard.banking"),
    },
    {
      label: i18n.t("walletcard.transaction"),
      code: "Transaction",
      icon: (
        <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF", }}>
          <Image
            width="30px"
            height="30px"
            src={PATH + `/assets/img/wallet/wallet_card/Transaction.png`}
          />
        </div>
      ),
      category: t("walletcard.banking"),
    },
    {
      label: i18n.t("walletcard.betrecord"),
      code: "Bet Record",
      icon: (
        <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF", }}>
          <Image
            width="30px"
            height="30px"
            src={PATH + `/assets/img/wallet/wallet_card/Bet Record.png`}
          />
        </div>
      ),
      category: t("walletcard.banking"),
    },
    {
      label: i18n.t("walletcard.promotionrecord"),
      code: "Promotion Record",
      icon: (
        <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF", }}>
          <Image
            width="30px"
            height="30px"
            src={PATH + `/assets/img/wallet/wallet_card/Promotion.png`}
          />
        </div>
      ),
      category: t("walletcard.banking"),
    },
    {
      label: i18n.t("walletcard.myaccount"),
      code: "My Account",
      icon: (
        <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF", }}>
          <Image
            width="30px"
            height="30px"
            src={PATH + `/assets/img/wallet/wallet_card/My Account.png`}
          />
        </div>
      ),
      category: t("walletcard.account"),
    },
    {
      label: i18n.t("walletcard.bankAccount"),
      code: "Bank Account",
      icon: (
        <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF", }}>
          <Image
            width="30px"
            height="30px"
            src={PATH + `/assets/img/wallet/wallet_card/Bank Account.png`}
          />
        </div>
      ),
      category: t("walletcard.account"),
    },
    {
      label: i18n.t("walletcard.changepassword"),
      code: "Change Password",
      icon: (
        <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF", }}>
          <Image
            width="30px"
            height="30px"
            src={PATH + `/assets/img/wallet/wallet_card/Change Password.png`}
          />
        </div>
      ),
      category: t("walletcard.account"),
    },
  ];

  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
    isSuccess: isUserSuccess,
    error: userError,
  } = useGetProfileQuery();

  const {
    data: shareLink,
    isLoading: isShareLinkLoading,
    isError: isShareLinkError,
    isSuccess: isShareLinkSuccess,
    error: shareLinkError,
  } = useGetShareLinkQuery();

  const {
    data: depositDetails,
    isLoading: isDepositDetailsLoading,
    isError: isDepositDetailsError,
    isSuccess: isDepositDetailsSuccess,
    error: depositDetailsError,
  } = useGetDepositDetailsQuery();

  const {
    data: withdrawDetails,
    isLoading: isWithdrawDetailsLoading,
    isError: isWithdrawDetailsError,
    isSuccess: isWithdrawDetailsSuccess,
    error: withdrawDetailsError,
  } = useGetWithdrawDetailsQuery();


  useEffect(() => {
    setSelectedScreen((location?.state?.screen || "My Account"));
  }, [location]);

  if (
    isUserLoading ||
    isShareLinkLoading ||
    isDepositDetailsLoading ||
    isWithdrawDetailsLoading
  ) {
    return <Backdrop />;
  }

  return (
    <>
      <Box
        px={2}
        py={3}
        sx={{
          background: `url('${user?.webBackground[0]?.thumbnailUrl}')`,
          minHeight: mobileView && "100vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {!tabletView && (
          <Container>
            <Grid container direction="row" spacing={3}>
              <Grid item xs={3}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  flexDirection="column"
                  // px={3}
                  sx={{
                    bgcolor: theme.palette.background.paper,
                    height: "100%",
                  }}
                >
                  <Box my={3} px={3}>
                    <Grid sx={{ flexGrow: 1 }} container>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item display="flex" alignSelf="center">
                            <Box
                              display="flex"
                              justifyContent="center"
                              pl="15px"
                              pr="20px"
                            >
                              <AccountCircleIcon />
                            </Box>
                          </Grid>
                          <Grid item display="flex" alignSelf="center">
                            <Box display="flex">
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                <Typography variant="body2" color="white">
                                  {user?.result?.username}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="white"
                                >{`${process.env.REACT_APP_CURRENCY_CODE} ${user?.result?.currentCredit}`}</Typography>
                              </Box>
                              <IconButton
                                onClick={() =>
                                  dispatch(
                                    apiSlice.util.invalidateTags(["Profile"])
                                  )
                                }
                              >
                                <Refresh />
                              </IconButton>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider />
                  <Box my={3}>
                    {screens?.map((item, idx, arr) => (
                      <>
                        {idx === 0 ? (
                          <Box
                            px={3}
                            py={1}
                            sx={{ backgroundColor: theme.palette.primary.main }}
                          >
                            <Typography color="white">
                              {item.category}
                            </Typography>
                          </Box>
                        ) : item?.category !== arr[idx - 1]?.category ? (
                          <Box
                            mt={3}
                            px={3}
                            py={1}
                            sx={{ backgroundColor: theme.palette.primary.main }}
                          >
                            <Typography color="white">
                              {item.category}
                            </Typography>
                          </Box>
                        ) : null}
                        <Box px={3}>
                          <ListItemButton
                            onClick={() => {
                              navigate(Constant.getPath("/profileWallet"), {
                                replace: true,
                                state: { screen: item.code },
                              });
                            }}
                          >
                            <Stack direction="row">
                              {item.icon}
                              <Typography pl={2} pt={1} color="white">
                                {item.label}
                              </Typography>
                            </Stack>
                          </ListItemButton>
                        </Box>
                      </>
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs direction="column" spacing={3}>
                <Grid item xs={1}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="row"
                    pb={3}
                  // sx={{
                  //   bgcolor: theme.palette.background.paper,
                  // }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      px={5}
                      alignItems="center"
                      justifyContent="center"
                      width="250px"
                      sx={{
                        bgcolor: theme.palette.background.paper,
                        border: 3,
                        borderRadius: 3,
                        borderColor: theme.palette.primary.main,
                      }}
                    >
                      <Typography variant="body2" color="white">
                        {user?.result?.playerRank}
                      </Typography>
                      <AccountCircleIcon />
                    </Box>
                    {/* <Divider orientation="vertical" flexItem /> */}
                    <Box
                      display="flex"
                      flexDirection="column"
                      px={5}
                      alignItems="center"
                      justifyContent="center"
                      width="250px"
                      sx={{
                        bgcolor: theme.palette.background.paper,
                        border: 3,
                        borderRadius: 3,
                        borderColor: theme.palette.primary.main,
                      }}
                    >
                      <Typography
                        variant="body2"
                        textAlign="center"
                        color="white"
                      >
                        {i18n.t("walletcard.registrationdate")}
                      </Typography>
                      <Typography color="white">
                        {user?.result?.dateRegistered}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      px={5}
                      alignItems="center"
                      justifyContent="center"
                      width="250px"
                      sx={{
                        bgcolor: theme.palette.background.paper,
                        border: 3,
                        borderRadius: 3,
                        borderColor: theme.palette.primary.main,
                      }}
                    >
                      <Typography
                        variant="body2"
                        textAlign="center"
                        color="white"
                      >
                        {i18n.t("walletcard.amountrequiredforupgrade")}
                      </Typography>
                      <Typography color="white">
                        {user?.result?.nextRankAmountRequired || 0}
                      </Typography>
                      <Typography
                        variant="body2"
                        color={theme.palette.primary.main}
                      >
                        {process.env.REACT_APP_CURRENCY_CODE}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    flexDirection="column"
                    px={3}
                    py={2}
                    sx={{
                      bgcolor: theme.palette.background.paper,
                      height: "100%",
                    }}
                  >
                    {selectedScreen?.toLowerCase() === "my account" && (
                      <UserProfile
                        user={user?.result}
                        useUpdateProfileMutation={useUpdateProfileMutation}
                        shareLink={shareLink?.result?.url}
                      />
                    )}
                    {selectedScreen?.toLowerCase() === "deposit" && (
                      <Deposit
                        depositDetails={depositDetails?.result}
                        useDepositMutation={useDepositMutation}
                      />
                    )}
                    {selectedScreen?.toLowerCase() === "withdrawal" && (
                      <Withdrawal
                        withdrawDetails={withdrawDetails?.result}
                        useWithdrawMutation={useWithdrawMutation}
                        user={user?.result}
                        useGetHistoryMutation={useGetHistoryMutation}
                        useGetBankAccountsQuery={useGetBankAccountsQuery}
                        useGetPromotionRecordMutation={useGetPromotionRecordMutation}
                        setSelectedScreen={setSelectedScreen}
                      />
                    )}
                    {selectedScreen?.toLowerCase() === "transaction" && (
                      <Transaction
                        useGetHistoryMutation={useGetHistoryMutation}
                      />
                    )}
                    {selectedScreen?.toLowerCase() === "bet record" && (
                      <BetRecord
                        useListBetHistoryMutation={useListBetHistoryMutation}
                      />
                    )}
                    {selectedScreen?.toLowerCase() === "promotion record" && (
                      <PromotionRecord
                        useGetPromotionRecordMutation={
                          useGetPromotionRecordMutation
                        }
                      />
                    )}
                    {selectedScreen?.toLowerCase() === "bank account" && (
                      <BankAccount
                        usePlayerBanksMutation={usePlayerBanksMutation}
                      />
                    )}
                    {selectedScreen.toLowerCase() === "change password" && (
                      <ChangePassword
                        useUpdateProfileMutation={useUpdateProfileMutation}
                      />
                    )}
                    {selectedScreen?.toLowerCase() === "promo center" && (
                      <PromoCenter
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        )}
        {tabletView && (
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              alignSelf="center"
              mt={{ xs: 6, sm: 0, md: 0, lg: 0 }}
            >
              {/* {screens?.map((item, index) => (
                  <Grid key={index} item xs={3} sm={2} md={2}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      p={1}
                    >
                      <Button
                        key={index}
                        disableRipple
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "transparent",
                          },
                          mb: 1,
                        }}
                        onClick={() => {
                          navigate("/profileWallet", {
                            replace: true,
                            state: { screen: item.label },
                          });
                        }}
                      >
                        <Box
                          display="flex"
                          sx={{
                            border: 1,
                            width: "50px",
                            height: "50px",
                          }}
                          justifyContent="center"
                          alignItems="center"
                        >
                          {item.icon}
                        </Box>
                      </Button>
                      <Typography
                        variant="body2"
                        fontSize="12px"
                        textAlign="center"
                      >
                        {item.label}
                      </Typography>
                    </Box>
                  </Grid>
                ))} */}
              <Box>
                <Box sx={{ display: "flex", pb: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      pr: 2,
                    }}
                  >
                    <Box
                      component="img"
                      width="20px"
                      height="20px"
                      src={PATH + "/assets/img/wallet/wallet_card/Bell.png"}
                    />
                    <Box display="flex">
                      <Typography fontSize={14} color="white">
                        {t("walletcard.walletbalance")}
                      </Typography>
                      <Typography fontSize={14} color={theme.palette.primary.main}>
                        {` (${process.env.REACT_APP_CURRENCY_CODE})`}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography fontSize={14} color="white">
                        {user?.result?.currentCredit}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          dispatch(apiSlice.util.invalidateTags(["Profile"]))
                        }
                      >
                        <Refresh style={{ color: "#29A829" }} />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      pl={2}
                      sx={{ display: "flex", alignItems: "flex-end" }}
                      onClick={() => {
                        navigate(Constant.getPath("/profileWallet"), {
                          state: { screen: "Transaction" },
                        });
                      }}
                    >
                      <Typography mr={1} fontSize={14} color="white">
                        {t("walletcard.transactionhistory")}
                      </Typography>
                      <Forward style={{ color: "#29A829" }} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <WalletCard screens={screens} token="true" />
            </Box>
            <Box mt={1} mb={mobileView ? 8 : 3}>
              <Box
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                py={3}
                sx={{
                  bgcolor: theme.palette.background.paper,
                }}
              >
                {selectedScreen?.toLowerCase() === "my account" && (
                  <UserProfile
                    user={user?.result}
                    useUpdateProfileMutation={useUpdateProfileMutation}
                    shareLink={shareLink?.result?.url}
                  />
                )}
                {selectedScreen?.toLowerCase() === "deposit" && (
                  <Deposit
                    depositDetails={depositDetails?.result}
                    useDepositMutation={useDepositMutation}
                  />
                )}
                {selectedScreen?.toLowerCase() === "withdrawal" && (
                  <Withdrawal
                    withdrawDetails={withdrawDetails?.result}
                    useWithdrawMutation={useWithdrawMutation}
                    user={user?.result}
                    useGetHistoryMutation={useGetHistoryMutation}
                    useGetBankAccountsQuery={useGetBankAccountsQuery}
                    useGetPromotionRecordMutation={useGetPromotionRecordMutation}
                    setSelectedScreen={setSelectedScreen}
                  />
                )}
                {selectedScreen?.toLowerCase() === "transaction" && (
                  <Transaction useGetHistoryMutation={useGetHistoryMutation} />
                )}
                {selectedScreen?.toLowerCase() === "bet record" && (
                  <BetRecord
                    useListBetHistoryMutation={useListBetHistoryMutation}
                  />
                )}
                {selectedScreen?.toLowerCase() === "promotion record" && (
                  <PromotionRecord
                    useGetPromotionRecordMutation={
                      useGetPromotionRecordMutation
                    }
                  />
                )}
                {selectedScreen?.toLowerCase() === "bank account" && (
                  <BankAccount
                    usePlayerBanksMutation={usePlayerBanksMutation}
                  />
                )}
                {selectedScreen?.toLowerCase() === "change password" && (
                  <ChangePassword
                    useUpdateProfileMutation={useUpdateProfileMutation}
                  />
                )}
                {selectedScreen?.toLowerCase() === "promo center" && (
                  <PromoCenter
                  />
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProfileWallet;
