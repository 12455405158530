import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
  useMediaQuery,
  Pagination,
  Stack,
  Divider,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DateRangePicker from "../form/DateRangePicker";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import { lighten } from "@material-ui/core";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import PendingIcon from '@mui/icons-material/Pending';

// not going to refactor this part for now
const Transaction = ({ useGetHistoryMutation }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const currentDate = new Date();
  const sevenDaysAgo = new Date();

  const category = [
    { label: t("walletcard.transactionpage.deposit"), value: "Deposit" },
    { label: t("walletcard.transactionpage.withdraw"), value: "Withdraw" },
  ];
  const type = [
    { label: t("walletcard.transactionpage.all"), value: "All" },
    { label: t("walletcard.transactionpage.approved"), value: "Approved" },
    { label: t("walletcard.transactionpage.rejected"), value: "Rejected" },
    { label: t("walletcard.transactionpage.pending"), value: "Pending" },
  ];

  const [transactionHistory, setTransactionHistory] = useState(
    category[0].value
  );
  const [statusType, setStatusType] = useState(type[0].value);
  const [startDate, setStartDate] = useState(
    sevenDaysAgo.setDate(currentDate.getDate() - 7)
  );
  const [endDate, setEndDate] = useState(currentDate);
  const [offset, setOffset] = useState(0);

  const [
    history,
    result,
    // { data, isLoading, isError, isSuccess, error },
  ] = useGetHistoryMutation();

  const handleOnChangeHistory = (event) => {
    setTransactionHistory(event.target.value);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleOnChangeType = (event) => {
    setStatusType(event.target.value);
  };

  const handlePageChange = (e, p) => {
    setOffset(p - 1);
  };

  useEffect(() => {
    if (!(endDate < startDate)) {
      let data = {
        criteria: {
          dateFrom: format(startDate, "dd-MM-yyyy"),
          dateTo: format(endDate, "dd-MM-yyyy"),
          status: statusType,
          type: transactionHistory.toLowerCase(),
        },
        offset: offset,
      };
      history(data).unwrap();
    }
  }, [transactionHistory, startDate, endDate, statusType, offset]);

  return (
    <Container>
      <Typography fontWeight="bold" color="white">
        {" "}
        {t("walletcard.transaction")}
      </Typography>
      <FormControl
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "15px",
          "& .MuiTextField-root": {
            pb: "30px",
          },
          "& .MuiSelect": {
            marginBottom: "30px",
          },
        }}
      >
        <InputLabel id="searchingTransaction">
          {t("walletcard.transactionpage.searchingtransaction")}
        </InputLabel>
        <Select
          value={transactionHistory}
          onChange={handleOnChangeHistory}
          label={t("walletcard.transactionpage.searchingtransaction")}
          labelId="searchingTransaction"
          size="small"
        >
          {category.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={mobileView ? "column" : "row"}
        mt="15px"
      >
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
          size="small"
        />
        <Box mt={mobileView ? "15px" : null}>
          <FormControl>
            <InputLabel id="type">
              {t("walletcard.transactionpage.type")}
            </InputLabel>
            <Select
              value={statusType}
              onChange={handleOnChangeType}
              label="Type"
              labelId="type"
              size="small"
            >
              {type.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      {!mobileView ? (
        <>
          <Box sx={{ height: 400, width: "100%", mt: "15px" }}>
            <DataGrid
              sx={{
                "& .MuiDialogContent-root": {
                  overflowY: "auto",
                  "::-webkit-scrollbar": {
                    width: "12px",
                  },
                  "::-webkit-scrollbar-track": {
                    background: theme.palette.text.disabled,
                  },
                  "::-webkit-scrollbar-thumb": {
                    background: theme.palette.background.paper,
                  },
                },
                color: "white",
              }}
              rows={
                result?.data?.result?.content.map((content, idx) => {

                  return {
                    ...content,
                    createdDate: format(
                      new Date(content?.createdDate),
                      "dd-MM-yyyy"
                    ),
                    id: (result?.data?.result?.pageable?.pageSize * result?.data?.result?.pageable?.pageNumber) + (idx + 1),
                  };
                }) || []
              }
              columns={[
                {
                  field: "id",
                  headerName: t("walletcard.transactionpage.id"),
                  width: 150,
                  editable: false,
                },
                {
                  field: "type",
                  headerName: t("walletcard.transactionpage.type"),
                  width: 150,
                  editable: false,
                },
                {
                  field: "amount",
                  headerName: t("walletcard.transactionpage.amount"),
                  width: 150,
                  editable: false,
                },
                {
                  field: "status",
                  headerName: t("walletcard.transactionpage.status"),
                  width: 150,
                  editable: false,
                },
                {
                  field: "createdDate",
                  headerName: t("walletcard.transactionpage.createddate"),
                  width: 150,
                  editable: false,
                },
                {
                  field: "reason",
                  headerName: "Reason",
                  width: 150,
                  editable: false,
                },
              ]}
              hideFooter={true}
              rowsPerPageOptions={[5]}
              // checkboxSelection
              // disableSelectionOnClick
              // isCellEditable={false}
              onPageChange={(newPage) => setOffset(newPage)}
            />
          </Box>
        </>
      ) : (
        <>
          <Box sx={{
            height: 400, width: "100%", mt: "15px", overflowY: "scroll",
            border: "1px solid white",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              background: "rgba(255, 255, 255, 0.1)",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#D1D1D1",
              borderRadius: "4px",
            },
          }}>
            {result?.data?.result?.content.length > 0 ? (
              result.data.result.content.map((item, idx) => (
                <React.Fragment key={idx}>
                  <Box
                    sx={{
                      padding: "10px 10px",
                      marginBottom: "5px",
                      color: "white",
                      overflow: "hidden",
                    }}
                  >
                    <Typography fontSize={13}>
                      <strong>ID:</strong> {(result.data.result.pageable.pageSize * result.data.result.pageable.pageNumber) + (idx + 1)}
                    </Typography>

                    {item.type === 'PAYMENT_GATEWAY' ?
                      <>
                        <Typography fontSize={13}><strong>Type:</strong> {item.gateway} </Typography>
                        <Typography fontSize={13}><strong>Bank Name:</strong> {item.pgBankName} </Typography>
                      </>
                      :
                      item.type === 'ONLINE_BANKING_ATM'
                        ? <Typography fontSize={13}><strong>Bank Name:</strong> {item?.bankDetail?.bankName}</Typography> : undefined}

                    <Typography fontSize={13}>
                      <strong>Amount:</strong> {item.amount}
                    </Typography>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <Typography sx={{ marginRight: 0.5 }} fontSize={13}>
                        <strong>Status:</strong> {item.status}
                      </Typography>
                      {
                        item.status === "PENDING" ?
                          <PendingIcon /> : item.status === "APPROVED" ?
                            <DoneIcon sx={{ color: "green" }} /> : item.status === "REJECTED" ?
                              <CloseIcon sx={{ color: "red" }} /> : undefined
                      }
                    </div>

                    <Typography fontSize={13}>
                      <strong>Created Date:</strong> {format(new Date(item.createdDate), "yyyy-MM-dd HH:mm")}
                    </Typography>
                    {item.reason ?
                      <Typography fontSize={13}>
                        <strong>Reason:</strong> {item.reason}
                      </Typography> : undefined
                    }

                  </Box>
                  <Divider sx={{ borderColor: "white" }} />
                </React.Fragment>
              ))
            ) : <Typography sx={{ color: "white", width: "100%", height: "100%", alignContent: "center", textAlign: "center" }}>
              No rows
            </Typography>}
          </Box>
        </>)}
      <Stack mt={2} spacing={2} size="small" alignItems="center">
        <Pagination
          // disabled={selectedAnnouncement.selected ? true : false}
          // onChange={handlePageChange}
          onChange={handlePageChange}
          count={
            result?.data?.result?.totalPages === 0
              ? 1
              : result?.data?.result?.totalPages
          }
          shape="rounded"
          sx={{
            "& .MuiPaginationItem-root": {
              color: "white",
              "&.Mui-selected": {
                background: lighten(theme.palette.background.paper, 0.02),
                color: "white",
              },
            },
          }}
        />
      </Stack>
    </Container>
  );
};

export default Transaction;
