import { apiSlice } from "./apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  tagTypes: [
    "Profile",
    "UpdateProfile",
    "RankInfo",
    "ShareLink",
    "Affiliate",
    "CurrentCredit",
    "BankAccounts",
    "PlayerBanks",
    "SaveTrackingData"
  ],
  endpoints: (builder) => ({
    listAffiliate: builder.mutation({
      query: (body) => ({
        url: "/user/api/v1/listAffiliate",
        method: "POST",
        body,
      }),
      providesTags: ["Affiliate"],
    }),
    getProfile: builder.query({
      query: () => "/user/api/v1/profile",
      providesTags: ["Profile"],
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: "/user/api/v1/profile",
        method: "PUT",
        body,
      }),
      providesTags: ["UpdateProfile"],
      invalidatesTag: ["Profile"],
    }),
    getRankInfo: builder.query({
      query: () => "/user/api/v1/rankInfo",
      providesTags: ["RankInfo"],
    }),
    getShareLink: builder.query({
      query: () => "/user/api/v1/shareLink",
      providesTags: ["ShareLink"],
    }),
    getBankAccounts: builder.query({
      query: () => "/user/api/v1/listBankAccounts",
      providesTags: ["BankAccounts"],
    }),
    playerBanks: builder.mutation({
      query: ( playerBank) => ({
        url: `/user/api/v1/addBankAccount`,
        method: "POST",
        body: playerBank,
      }),
      providesTags: ["PlayerBanks"],
    }),
    saveTrackingData: builder.mutation({
      query: (trackingData) => ({
        url: `/user/api/v1/saveTrackingData`,
        method: "POST",
        body: trackingData,
      }),
      providesTags: ["SaveTrackingData"],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useGetRankInfoQuery,
  useGetShareLinkQuery,
  useGetBankAccountsQuery,
  usePlayerBanksMutation,
  useSaveTrackingDataMutation,
} = userApiSlice;
